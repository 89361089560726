import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { Props } from 'src/features/@types/customIconify';

// ------------------------------------------------------------

export default function CustomIconify({ href, icon, label }: Props) {
  return (
    <Link to={href} target="_blank" rel="noopener noreferrer">
      <Tooltip title={label}>
        <Iconify icon={icon} width="30px" />
      </Tooltip>
    </Link>
  );
}
