import { Divider, Stack, styled } from '@mui/material';

// -------------------------------------------------------------

export const Container = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  justifyContent: 'center',
  backgroundColor: '#DBDEE1',
  alignItems: 'center',

  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
}));

export const ContainerImage = styled(Stack)(() => ({
  width: '300px',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'auto',
  alignItems: 'center',
  backgroundSize: 'cover',
  backgroundPposition: 'center',
  flexGrow: 1,
}));

export const SocialMidiaIcon = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
}));

export const SocialMediaContainer = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const DashedDivider = styled(Divider)(() => ({
  borderStyle: 'dashed',
  my: 8,
  borderWidth: '0,5px',
  backgroundColor: '#1d3c5a',
  width: '100%',
}));
