import { CardMedia, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomIconify from 'src/features/components/customIconify';
import { Animate } from 'src/features/global/styles';
import {
  Container,
  ContainerImage,
  DashedDivider,
  SocialMediaContainer,
  SocialMidiaIcon,
} from './styles';

// ---------------------------------------------------------------------------

export default function FooterMob() {
  return (
    <Stack style={{ width: '100%' }}>
      <Container>
        <Stack style={{ width: '70%' }}>
          <CardMedia
            component="img"
            alt="Logo do Footer"
            image="images/logo.webp"
            width={200}
            height={200}
          />
        </Stack>

        <DashedDivider />

        <Stack style={{ gap: 20 }} mt={3} mb={3}>
          <Typography gutterBottom variant="h5" component="div" align="center">
            Siga-nos nas redes sociais
          </Typography>

          <SocialMediaContainer>
            <SocialMidiaIcon>
              <Animate>
                <CustomIconify
                  icon="skill-icons:instagram"
                  href="https://www.instagram.com/pafesan.toldos/"
                  label="Istagram"
                />
              </Animate>
              <Animate>
                <CustomIconify
                  icon="logos:facebook"
                  href="https://www.facebook.com/profile.php?id=61556556355243"
                  label="Facebook"
                />
              </Animate>
            </SocialMidiaIcon>
          </SocialMediaContainer>
        </Stack>

        <DashedDivider />

        <Stack mt={3} mb={3}>
          <Animate>
            <Link to="https://www.fullcodedev.com" target="_blank" rel="noopener noreferrer">
              <ContainerImage>
                <CardMedia
                  component="img"
                  alt="Logo do Desenvolvedor"
                  image="images/fullcode.webp"
                  width={250}
                  height="auto"
                />
              </ContainerImage>
            </Link>
          </Animate>
          <Typography gutterBottom variant="body1" component="div" align="center" mt={3}>
            Copyright 2024 <br />
            Todos os direitos reservados
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
}
