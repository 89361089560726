import { Box, BoxProps, Link, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="images/logos.webp"
        alt="Logo da Pafesan Toldos e Fachadas"
        width="auto"
        height="auto"
        maxWidth="80px"
        maxHeight="40px"
        sx={{ cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}

        <Typography variant="h4" style={{ fontWeight: 'bold', color: 'black' }}>
          PAFESAN
        </Typography>
      </Link>
    );
  }
);

export default Logo;
