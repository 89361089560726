import { Stack, styled } from '@mui/material';
import { Props } from '../@types/animate';

// ------------------------------------------------------------


export const Animate = styled(Stack)<Props>(({ width }: Props) => ({
  width,
  transformOrigin: '50% 50%',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));
