import { CardMedia, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomIconify from 'src/features/components/customIconify';
import { Animate } from 'src/features/global/styles';
import {
  Container,
  ContainerImage,
  ContentContainer,
  DashedDivider,
  SocialMediaContainer,
  SocialMidiaIcon,
} from './styles';

// -----------------------------------------------------------------------------------------

export default function Footer() {
  return (
    <Container>
      <ContentContainer id="Session 1">
        <ContainerImage>
          <CardMedia
            component="img"
            alt="Logo do Footer"
            image="images/logo.webp"
            width={200}
            height={200}
          />
        </ContainerImage>
      </ContentContainer>

      <DashedDivider orientation="vertical" />

      <ContentContainer id="Session 2">
        <SocialMediaContainer>
          <Typography gutterBottom variant="h5" component="div" align="center">
            Siga-nos nas redes sociais
          </Typography>

          <SocialMidiaIcon>
            <Animate>
              <CustomIconify
                icon="skill-icons:instagram"
                href="https://www.instagram.com/pafesan.toldos/"
                label="Instagram"
              />
            </Animate>
            <Animate>
              <CustomIconify
                icon="logos:facebook"
                href="https://www.facebook.com/profile.php?id=61556556355243"
                label="Facebook"
              />
            </Animate>
          </SocialMidiaIcon>
        </SocialMediaContainer>
      </ContentContainer>

      <DashedDivider orientation="vertical" />

      <ContentContainer id="Session 3">
        <Animate>
          <Link to="https://www.fullcodedev.com" target="_blank" rel="noopener noreferrer">
            <ContainerImage>
              <Tooltip title="Clique aqui e conheça o desenvolvedor">
                <CardMedia
                  component="img"
                  alt="Logo do Desenvolvedor"
                  image="images/fullcode.webp"
                  width="auto"
                  height="auto"
                />
              </Tooltip>
            </ContainerImage>
          </Link>
        </Animate>
        <Typography gutterBottom variant="body1" component="div" align="center" mt={5}>
          Full Code Software e Tecnologia <br /> Copyright 2024 - Todos os direitos reservados
        </Typography>
      </ContentContainer>
    </Container>
  );
}
