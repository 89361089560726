import { PATH_CATALOG } from 'src/routes/paths';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Catálogo',
    path: '/catalogo',
    icon: <Iconify icon="carbon:catalog" />,
    children: [
      {
        subheader: '',
        items: [
          { title: 'Toldos em Lona', path: PATH_CATALOG.toldosLona },
          { title: 'Toldos em Policarbonato', path: PATH_CATALOG.toldosPolicarbonato },
          { title: 'Toldos Garagem', path: PATH_CATALOG.toldosGaragem },
          { title: 'Toldos Motorizados', path: PATH_CATALOG.toldosMotorizados },
          { title: 'Toldos Cortina', path: PATH_CATALOG.toldosCortina },
          { title: 'Estruturas e Esquadrias', path: PATH_CATALOG.esquadrias },
          { title: 'Toldos Sobriletes', path: PATH_CATALOG.sombriletes },
          { title: 'Grades e Portões', path: PATH_CATALOG.gradesPortoes },
        ],
      },
    ],
  },
  {
    title: 'Fotos em Destaque',
    icon: <Iconify icon="icon-park-twotone:picture-album" />,
    path: 'destaques',
  },
];

export default navConfig;
